$(document).ready(function(){
    $(document).on('click', 'input[type=radio][name="job[license_type]"]', ()=>{
        let license_class = $('input[type=radio][name="job[license_type]"]:checked', '#new_job_form').val();
        if (license_class) {
            $.post({
                url: "/license_class_vehicles",
                data: {license_class},
                success: (response) => {
                    $('#license_class_select').html(response)
                },
                error: (error) => {
                    console.log(error)
                }
            });
        }
    });

    if (!$('#shift_preferences_weekdays').is(':checked')) {
        hideWeekdays();
    }

    if (!$('#shift_preferences_weekends').is(':checked')) {
        hideWeekends();
    }

    $(document).on('change', "#driver_profile_hour_rate", function(){
        $("#matching_rate").html(`$${$(this).val()}/hr and Up`)
    });

    $(document).on('change', "#shift_preferences_weekdays", function(){
        if ($(this).is(':checked')) {
            showWeekdays();
        } 
        else {
            hideWeekdays();
        }
    });

    $(document).on('change', "input[id^=job_employment_type_]", function () {
        const emp_type = $('input[id^=job_employment_type_]:checked').val();
        if (emp_type === "direct_hire") {
            toggleRateTypes();
            const rate_type =  $('input[id^=job_rate_type_]:checked');
            console.log(rate_type.val());
            if (['flat_rate', 'piece_rate'].includes(rate_type.val())){
                $('input[id^=job_rate_type_]').prop('checked', false);
            }
        } else {
            toggleRateTypes('enable')
        }
    });

    $(document).on('change', "#shift_preferences_weekends", function(){
        if ($(this).is(':checked')) {
            showWeekends();
        } 
        else {
            hideWeekends();
        }
    });

    function showWeekdays(){
        $('.weekdays_start_and_end_times').show();
        $('#job_shift_preferences_weekdays_start_time').attr('required', 'required')
        $('#job_shift_preferences_weekdays_end_time').attr('required', 'required')
    };

    function hideWeekdays(){
        $('.weekdays_start_and_end_times').hide();
        $('#job_shift_preferences_weekdays_start_time').removeAttr('required')
        $('#job_shift_preferences_weekdays_end_time').removeAttr('required')
    };

    function showWeekends(){
        $('.weekends_start_and_end_times').show();
        $('#job_shift_preferences_weekends_start_time').attr('required', 'required')
        $('#job_shift_preferences_weekends_end_time').attr('required', 'required')
    };

    function hideWeekends(){
        $('.weekends_start_and_end_times').hide();
        $('#job_shift_preferences_weekends_start_time').removeAttr('required')
        $('#job_shift_preferences_weekends_end_time').removeAttr('required')
    };

    $(document).on('click', ".trigger_click", (e)=>{
        e.preventDefault();
        const id = e.target.id;
        const parts = id.split('__');
        if (parts.length === 2)
            $(`#${parts[1]}`).trigger('click')
    });

    $(document).on("click", "input[id$=_form_submit]", function(e){
        e.preventDefault();
        const button_id = e.target.id;
        const form_id = button_id.replace('_submit', '');
        validate_form(form_id);
    });

    $(document).on('change', 'input[type=radio][name="driver_profile[employment_type]"]', function (){
        if ( this.value === 'independent_contractor') {
            $('#contractor_fields').removeClass('hidden')
            $('#driver_profile_business_name').attr('required', 'required')
            $('#driver_profile_hst_number').attr('required', 'required')
        } else {
            $('#contractor_fields').addClass('hidden')
            $('#driver_profile_business_name').removeAttr('required')
            $('#driver_profile_hst_number').removeAttr('required')
        }
    });

    $(document).on('change', '#driver_profile_driving_school', function (){
        if ($(this).val() === 'other'){
            $('#driver_profile_other_driving_school_name').removeClass('hidden')
            $('#driver_profile_other_driving_school_name').attr('required', 'required')
        }
        else {
            $('#driver_profile_other_driving_school_name').addClass('hidden')
            $('#driver_profile_other_driving_school_name').removeAttr('required')
        }
    });

    $(document).on('change', '#driver_profile_has_no_resume', function (){
        if(document.getElementById('driver_profile_has_no_resume').checked) {
            $("#employment_references").show();
        } else {
            $("#employment_references").hide();
        }
    });
});

const humanize = (str, allCaps = false) => {
    if (str === null || str === 'null' || str === undefined) return '';
    let frags = str.split(/[_\[]/);
    for (let i=0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1).replace(']', '');
    }
    return allCaps ? frags.join(' ').toUpperCase() : frags.join(' ');
};

const disableButton = (el, disable = true) => {
    $(el).attr('disabled', disable);
    if (disable) {
        $(el).addClass('btn-disabled');
        $(el).removeClass('btn-primary');
    } else {
        $(el).addClass('btn-primary');
        $(el).removeClass('btn-disabled');
    }
};

const validate_form = (form_id) => {
    removeErrors();
    let errors = 0;
    // Upload files
    $(`#${form_id} input[type=file]:required`).each(function(e){
        if ($(this).val() === '') {
            const is_resume_form = $(this).attr('id') === "driver_profile_resume_files"
            if (!is_resume_form || (is_resume_form && !document.getElementById('driver_profile_has_no_resume').checked)) {
                errors++;
                showFormFieldError(this, 'file');
            }
        }
    });
    // Textfields
    $(`#${form_id} input[type=text]:required,[type=password]:required`).each(function(e){
        if ($(this).val() === '') {
            errors++;
            showFormFieldError(this, 'text');
        }
        else if ($(this).data('pattern')) {
            const pattern = new RegExp($(this).data('pattern'))
            if (!$(this).val().match(pattern)) {
                errors++;
                showFormFieldError(this, 'text');
            }
        }
    });
    // Radio buttons
    $(`#${form_id} input[type=radio]:required`).each(function(){
        if($(this).attr("data-error")) {
            const field_name = $(this).attr('name');
            let radio_checked = false;
            if ($(`input[name="${field_name}"]`).is(':checked')) {
                radio_checked = true;
            }
            if (!radio_checked) {
                errors++;
                showFormFieldError(this, 'radio');
            }
        }
    });
    // Select menus
    $(`#${form_id} select:required`).each(function(){
        if ($(this).val() === '') {
            errors++;
            showFormFieldError(this, 'select');}
    });
    // Checkbox
    $(`#${form_id} input[type=checkbox]:required`).each(function(){
        if($(this).attr("data-error")) {
            const field_name = $(this).attr('name');
            if ($(`input[name="${field_name}"]:checked`).length === 0){
                errors++;
                showFormFieldError(this, 'checkbox-accept');
            }
        }
    });
    if (errors === 0) {
        disableButton($(`#${form_id}_submit`));
        $(`#${form_id}`).submit();
    } else {
        $('#form_errors').html(`${errors} error(s) found. Please review the highlighted fields below!`)
        $('#form_errors').show()
    }
};

const showFormFieldError = (el, type) => {
    if (!['radio', 'checkbox'].includes(type)){
        $(el).addClass('border-error');
    }
    const error_message = $(el).attr('data-error');
    if (error_message){
        if (type == "radio") {
            $(el).parent().parent().before(`<div class='error_msg caption1 text-red-600'>${error_message}</div>`)
        } else {
            $(el).before(`<div class='error_msg caption1 text-red-600'>${error_message}</div>`)
        }
    }
};

const removeErrors = () => {
    $('.announcement-success').remove();
    $('.error_msg').remove();
    $('#form_errors').hide();
    $('.border-error').each(function(){
        $(this).removeClass('border-error')
    })
};

const toggleRateTypes = (action = 'disable') => {
    if (action === 'disable') {
        $('#job_rate_type_flat_rate').attr('disabled', 'disabled').addClass("cursor-not-allowed").next().addClass("text-gray-400");
        $('#job_rate_type_piece_rate').attr('disabled', 'disabled').addClass("cursor-not-allowed").next().addClass("text-gray-400");
    } else {
        $('#job_rate_type_flat_rate').removeAttr('disabled').removeClass("cursor-not-allowed").next().removeClass("text-gray-400");
        $('#job_rate_type_piece_rate').removeAttr('disabled').removeClass("cursor-not-allowed").next().removeClass("text-gray-400");
    }
}